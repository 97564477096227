import { navigateToUrl, registerApplication, start } from "single-spa";

singleSpaStart();

let isAuthenticated = false;
async function singleSpaStart() {
  const defaultUrl = "/launchpad";
  const {
    auth$,
    initializeAuth,
    extractTokenFromHash,
    removeTokensFromStorage,
  } = await import("@imaginelearning/link-player-api");

  const { OKTA_REDIRECT_ROUTE } = await import(
    "@imaginelearning/link-player-auth"
  );

  const hasTokenInHash = !!extractTokenFromHash()?.accessToken;
  if (hasTokenInHash) {
    // New token provided, remove any previously stored tokens.
    removeTokensFromStorage();
  }

  if (window.location.pathname !== "/test-logout") {
    await initializeAuth();
  }

  registerApplication(
    "pages",
    // @ts-ignore
    () => import("link-player-pages"),
    (location) =>
      !(
        location.pathname.startsWith("/host") ||
        location.pathname.startsWith("/preview")
      )
  );

  registerApplication(
    "host",
    () => {
      // @ts-ignore
      const host = import("link-player-host/single-spa");
      return host;
    },
    (location) =>
      location.pathname.startsWith("/host") ||
      location.pathname.startsWith("/preview")
  );

  auth$.subscribe((value) => {
    isAuthenticated = value.isAuthenticated;
    const query = new URLSearchParams(window.location.search);
    const redirectUri = query.get("redirect_uri");

    if (window.location.pathname === OKTA_REDIRECT_ROUTE) {
      return;
    }

    if (
      !isAuthenticated &&
      !["/login", "/test-logout"].includes(window.location.pathname)
    ) {
      if (redirectUri) {
        const hasQueryString = redirectUri?.indexOf("?") >= 0;
        const reason = "Unauthorized";

        const reasonValue = hasQueryString
          ? "&reason=" + reason
          : "?reason=" + reason;
        navigateToUrl(
          `${
            redirectUri.match(/^https?:\/\//) ? "" : "https://"
          }${decodeURIComponent(redirectUri)}${reasonValue}`
        );
        return;
      }
      navigateToUrl("/login" + window.location.search);
    }

    if (isAuthenticated && ["/", "/login"].includes(window.location.pathname)) {
      navigateToUrl(defaultUrl + window.location.search);
    }
  });

  start();
}
